import React, { useEffect, useState } from 'react'

const Competition = () => {
  const [data, setData] = useState([])

  let fetchData = async()=>{
    let response = await fetch('https://test.planholidays.co.uk/destination')
    response = await response.json()
    setData(response)
    console.log(response)
    console.log(data)
  }

  useEffect(()=>{
    fetchData()
  },[])


  return (
    <div className='container'>
    <div className='row'>
      {
        data.slice(0,9).map((x, index) => (
          <div className="col-lg-6 col-md-6 col-12 my-2" key={index}>
          <div className='position-relative' style={{height:"22rem"}}>
            <div className='scale-hover'>
              <img src={`../new/${x.image[0]}`} className='w-100' style={{height:"240px", backgroundSize:"contain"}} alt="" />
            </div>
            <div className="fnt-15 fw-semibold bg-light-blue px-2 pt-1 text-black">{x.title}</div>
            <div
              className='bg-light-blue w-100 text-black'
              style={{bottom: 0, left: 0 }}>
                <div className="row pt-2">
                  <div className="col ps-4">
                    <div className="fnt-12">{x.city}</div>
                    <div className="h6 fw-semibold">SAVE {x.off}%</div>
                  </div>
                  <div className="col text-center">
                    <div className="fnt-12">{x.packageDays} hb from</div>
                    <div className="h5">£{x.price}/<span className='fnt-12 '>pp</span></div>
                  </div>
                    <button className='btn btn-info text-white py-0 fnt-12 rounded-0'>View More</button>
                </div>
            </div>
          </div>
        </div>
        ))
      }
    </div>
    </div>
  )
}

export default Competition