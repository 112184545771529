import React, { createContext, useState } from 'react'

let CatContext = createContext()
export const CategoryContext = ({children}) =>{
    const [item, setItem] = useState('')
    const [res, setRes] = useState([])
    return (
        <CatContext.Provider value={{ item, setItem, res, setRes }}>
            {children}
        </CatContext.Provider>
    )
}

export default CatContext