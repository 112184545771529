import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const TermsCondition = () => {
  return (
    <div>
      <Navbar />
      <div className="container mt-5">
        <h4 className='h2 text-center my-4 fw-semibold '>Terms and Conditions</h4>
        <div>
          <p className='fnt-14'>This website located at planholidays.co.uk is owned and operated by planholidays.co.uk <br /> If you do not agree to be bound by and comply with these terms of use, please do not access or use this site. <br />References in these terms of use to “you”, “your” or “yours” are to be taken as references to the site user and the user’s company, except where stated or where the context requires otherwise. References to “us” or “we” are to be taken as references to planholidays.co.uk.</p>
          <p className='fnt-14 my-1'><span className='fw-semibold'>1. LIMITS ON USE:</span> You may not use this site or the services offered on or through it for personal gain and you may not sell or provide to any third party, or otherwise profit from, any services or information (nor any modification, adaptation or analysis of them) available on or through this site.</p>
          <p className="fnt-14"><span className="fw-semibold">2. SECURITY:</span> We reserve the right from time to time to: (a) alter and/or introduce new and/or additional security measures without notice; (b) temporarily withdraw and/or limit the availability of this site to any individual(s); and (c) do anything else that we believe necessary to preserve the security and integrity of this site and the information held in this site’s databases.</p>
          <p className="fnt-14"><span className="fw-semibold">3. ACCURACY & SITE CONTENT:</span>We use reasonable skill and care in compiling the information accessible on and through this site. However, certain information may have been compiled and/or provided to us by a third party who is not a member of our network. planholidays.co.uk does not accept responsibility for the accuracy of any information which is obtained from any such third party. Furthermore, errors and omissions in the information accessible on and through this site may occur because of a number of factors which are inherent in any internet-related system and which are not within our reasonable control. For example, information may be affected by machine, software or operator error, or malfunctions in connection with data transmission. In view of this, the information is provided “as is” and you should always attempt to verify the accuracy of any information obtained from this site before relying upon it.
            This is a dynamic site and as a result, the content and look and feel may change from time to time without prior notice.</p>
          <p className="fnt-14"><span className="fw-semibold">4. LIMITED LICENCE & OWNERSHIP:</span> You are granted a limited license to download the materials contained on this site to a personal computer, and to print a hard copy of such materials, solely for your personal, non-commercial use, provided that all copyright, trade mark and other proprietary notices are left intact.
            Save as expressly and specifically permitted in these terms of use, all translation, re-transmission, distribution or other use of the materials contained on this site and in its databases on any other internet site or other media is strictly prohibited. You are also prohibited from: (a) framing this site or any part of it on any other site; or (b) mirroring this site on another server; or (c) using automated screen capture or screen scraping technologies to obtain information of any sort from this site.
            The grant of this limited licence is conditional upon your agreement to and compliance with all of these terms of use.
            <br />Software (if any) made available to download from this site is either owned by or licensed to us. You may only use such software in accordance with the end user licence accompanying that software or, where there is no such licence; you are granted a non-exclusive, non-transferable licence to use the said software for using this site in accordance with these terms of use.
            <br />All copyright and all other intellectual property and proprietary rights of any sort relating in any way to this site (including, but not limited to, those relating to its content, branding and the services, software and any other materials made available on and through it) which are not granted to you in accordance with these terms of use are hereby expressly reserved to planholidays.co.uk or, in the case of any other brands, names and logos featured on this site, their respective owners.</p>
          <p className="fnt-14"><span className="fw-semibold">5. PRIVACY POLICY:</span> When you visit this site we will collect data that will personally identify you (“User Data”) e.g. when you use the site’s “contact us” facilities.
We will only use the User Data to: (a) contact you and provide you with information (as requested); and (b) deal with any other matters arising as a result of that contact.
<br />Subject to the provisions of applicable law and for a small fee you may obtain copy(ies) of the User Data (if any) we hold about you by contacting us via the site’s “contact us” facility.
<br />Additionally, if you wish to delete, deactivate or amend the User Data, or find out what User Data (if any) we are holding about you, please contact us via the site’s “contact us” facility. We will not sell the User Data to anyone, but, for the avoidance of doubt, the User Data may be transferred to a future purchaser of planholidays.co.uk, all or part of the planholidays.co.uk Group of companies and/or all or some of its assets.
<br />We will not disclose the User Data outside the planholidays.co.uk except as necessary to deal with matters arising from your use of the site and your contact(s) with us, or in the unlikely event that we believe in good faith that we are required to do so: (a) by court order or other legal/regulatory requirement; or (b) in order to protect our rights/property or those of our clients and/or their employees.
<br />This site does not utilise cookies.</p>
          <p className="fnt-14"><span className="fw-semibold ">Your Consent:</span> by using this site you consent to planholidays.co.uk, processing and transferring (both inside and outside the EEA) the User Data as necessary for us to provide you with and enable you to use the site and for the purposes referred to above.</p>
          <p className="fnt-14"><span className="fw-semibold">6. LINKS:</span> Where this site contains links to third party sites you should be aware that we may not control the sites reached through those links. We are not responsible for the content or privacy practices of sites which we do not control.</p>
          <span className="fw-semibold">7. DISCLAIMER AND LIMITATION OF LIABILITY </span>
          <p className="fnt-14">(a) Except as may be set out in these terms of use, this site, its content, and any use you choose to make of it, are provided and permitted without any representations or warranties of any kind, whether express or implied, and we disclaim all such representations and warranties so far as permitted by law. We do not warrant that information contained on or accessed through this site is adequate, complete, accurate or up-to-date, nor do we warrant that the site (or any part of it) will always be accessible, fully functional or free from errors and viruses. Accordingly, you agree that the only remedy available to you for breach of these terms of use shall be for our breach of contract under these terms of use.
          <br />(b) Subject to paragraph (d) below, but otherwise so far as permitted by law, we exclude and are not liable for all loss, damage(s) and liability (whether or not caused by our negligence) for all wasted time, all loss/corruption of data, all loss of profits, opportunities, and goodwill, and all indirect, consequential and punitive loss or damage(s) arising from or in relation to use of this site and/or the information on it or any services provided through it, howsoever arising, whether in contract, tort (including negligence), statute or otherwise, and even if it was reasonably foreseeable or we have been made aware of the possibility of such loss/damage(s)/liability.
          <br />(c) Subject to paragraph (d) below, but otherwise so far as permitted by law, our total aggregate liability hereunder in respect of direct loss and damage and other direct liability, howsoever arising, whether in contract, tort (including negligence), statute or otherwise shall be limited per claim or series of related claims, to £100.
          <br />(d) Nothing in these terms of use shall limit our liability for:
          <br />(i) loss or damage for death or personal injury caused by our negligence; or
          <br />(ii) for any fraudulent misrepresentation made by us or those under our control.
         <br /> (e) If you are using this site as a consumer then nothing in these terms of use shall in any way limit your statutory rights.</p>
          <p className="fnt-14"><span className="fw-semibold">8. SEVERABILITY:</span> If one or more of the provisions of these terms of use are at any time found to be invalid by a court, tribunal or other forum of competent jurisdiction, or otherwise rendered unenforceable, that decision shall not invalidate or void the remainder of these terms of use. These terms of use shall be deemed amended by modifying or severing such provisions as necessary to render them valid, legal and enforceable while preserving their intent, or if that is not possible, by substituting another provision that is valid, legal and enforceable which materially gives effect to their intent. Any invalid or unenforceable provision or provisions shall be severable from these terms of use so that the validity or enforceability of their remaining provisions, or the validity of the provision(s) in question in any other jurisdiction shall not be affected.</p>
          <p className="fnt-14"><span className="fw-semibold">9. ENTIRE AGREEMENT:</span> These terms of use supersede all prior agreements, arrangements and statements (except for those for which liability is not excluded) between us with respect to your use of this site and constitute the entire agreement between us relating to the same.</p>
          <p className="fnt-14"><span className="fw-semibold">10. CHANGE OF TERMS OF USE:</span> We may alter, adapt or otherwise change these terms of use from time to time (for security, legal or regulatory reasons, or to reflect updates or changes to the services or functionality of the site) without prior notice. Your continued use of this site will constitute acceptance of those amended terms of use and you should review them before making any further use of this site.</p>
          <p className="fnt-14"><span className="fw-semibold">11. ABOUT US:</span> This site is made available to you by planholidays.co.uk Plc (Regd Branch Office: 13 High Rd, London NW10 2TE, Company No. 07083563) and is © planholidays.co.uk. All rights reserved.
          <br />Our normal hours of business are 0900 to 1800 weekdays excluding Bank Holidays
You can contact us by writing to us at the above address or by using the ‘contact us’ facility on this site.
          </p>
          <p className="fnt-14"><span className="fw-semibold">12. MISCELLANEOUS:</span> These terms of use shall not constitute or be deemed to constitute a partnership, joint venture or contract of employment between you and us.
          <br />You may not assign, sub-license, sub-contract or otherwise transfer or deal in any of your rights or obligations under these terms of use without our prior written consent.
          <br />Headings in these terms of use are for convenience only and are not to be taken into account when construing these terms of use.</p>
          <p className="fnt-14"><span className="fw-semibold">13. TERMINATION:</span>We reserve the right to block your access to this site immediately and without notice if, in good faith, we believe that you have failed to comply with any of these terms of use.</p>
          <p className="fnt-14"><span className="fw-semibold">14. RIGHTS OF THIRD PARTIES:</span> No one other than you and us will have any right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of these terms of use, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. For the avoidance of doubt, nothing in this clause shall affect the rights of any permitted assignee or transferee.</p>
          <p className="fnt-14"><span className="fw-semibold">15. GOVERNING LAW:</span> Use of this site and the Service is governed by English law, and you irrevocably agree to submit any claim or dispute arising in relation to, out of, or in connection with this site (and any use you make of it or the Service and/or opportunities offered through it) to the non-exclusive jurisdiction of the English courts.</p>

        </div>
      </div>

      <Footer />
    </div>
  )
}

export default TermsCondition