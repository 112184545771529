import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Competition from './Pages/Competition';
import Contact from './Pages/Contact';
import CustomerProtection from './Pages/CustomerProtection';
import Destination from './Pages/Destination';
import Home from './Pages/Home';
import Inquire from './Pages/Inquire';
import JoinOurTeam from './Pages/JoinOurTeam';
import MultiDestionation from './Pages/MultiDestionation';
import MulltiDestinationDetail from './Pages/MulltiDestinationDetail';
import TermsCondition from './Pages/TermsCondition';
import PrivacyNotice from './Pages/PrivacyNotice';
import HolidaysSearch from './Pages/HolidaysSearch';
import DestinationCard from './Pages/DestinationCard';
import DestinationCard2 from './Pages/DestinationCard2';
import DestinationDetail from './Pages/DestinationDetail';
import Atol from './Pages/Atol';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const [showBox, setShowBox] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  

  const handleButtonClick = () => {
    setShowBox(prev => !prev);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!phoneNumber || !emailAddress) {
      alert('Please enter a phone number');
      return;
    }
  
    try {
      const response = await fetch('https://test.planholidays.co.uk/call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ call: phoneNumber, email: emailAddress }),  
      });
  
      if (response.ok) {
        alert('Your response submitted. We will call you shortly!');
        setPhoneNumber('');
        setEmailAddress('');
        setShowBox(false);  
      } else {
        alert('There was an issue with your request');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong. Please try again later.');
    }
  };

  return (
    <div>
      <ScrollToTop />

      <button
        className="btn btn-primary fnt-14 btn-blue"
        onClick={handleButtonClick}
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          zIndex: 1000,
        }}
      >
        Call Me <br /> Back
      </button>

      {showBox && (
        <div
          className="box border border-2 border-secondary"
          style={{
            position: 'fixed',
            bottom: '90px',
            left: '20px',
            padding: '10px',
            backgroundColor: 'white',
            borderRadius: '5px',
            zIndex: 999,
            width: '300px',
          }}
        >
          <div className="text-center h6">Speak to a Travel Expert now!</div>
          <hr />
          <p className="fnt-13">
            For a free callback, enter your number below and{' '}
            <span className="fw-semibold">we will call you in a few seconds.</span>
          </p>
          <div className="border rounded-2">
            <button className="btn btn-light fnt-14">
              <i className="fa-solid fa-phone text-blue" />
            </button>
            <input
              type="number"
              className="border-none outline-none px-3 fnt-14"
              placeholder="Enter your number"
              value={phoneNumber}
              onChange={(e)=>setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="border rounded-2 mt-1">
            <button className="btn btn-light fnt-14">
              <i className="fa-solid fa-envelope text-blue" />
            </button>
            <input
              type="email"
              className="border-none outline-none px-3 fnt-14"
              placeholder="Enter your email"
              value={emailAddress}
              onChange={(e)=>setEmailAddress(e.target.value)}
            />
          </div>
          <hr />
          <div className="fnt-12 text-secondary">
            Note: If you request a callback outside our office hours, you will be directed to our voicemail system where you can leave us a message.
          </div>
          <button
            className="btn btn-blue text-white fw-semibold fnt-14 mt-2 h5 w-100 py-2"
            onClick={handleSubmit}
          >
            Call Me
          </button>
        </div>
      )}

      <a href="https://api.whatsapp.com/send?phone=+442046189248&text=I%27m%20interested%20in%20Holiday%20Packages">
      <img 
        src="../assets/gifwhatsapp.gif" 
        alt="bottom-right-image" 
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          zIndex: 999,
          width: '80px',
          height: 'auto',
        }}
      />
      </a>

      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/competition" element={<Competition />} />
        <Route path="/customer-protection" element={<CustomerProtection />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/join-our-team" element={<JoinOurTeam />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/atol" element={<Atol />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/multi-destination-packages" element={<MultiDestionation />} />
        <Route path="/multi-destination/:name" element={<MulltiDestinationDetail />} />
        <Route path="/destination/:name" element={<DestinationCard />} />
        <Route path="/destination/:name/:name" element={<DestinationCard2 />} />
        <Route path="/destination/detail/:id" element={<DestinationDetail />} />
        <Route path="/holidays" element={<HolidaysSearch />} />
        <Route path="/inquire" element={<Inquire />} />
      </Routes>
    </div>
  );
};

export default App;
