import React, { useContext, useEffect, useState } from 'react'
import CatContext from '../Context/Context';
import Navbar from '../Components/Navbar'
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer'

const DestinationCard = () => {
  const [data, setData] = useState([])
  const { item, setItem } = useContext(CatContext);
  const [loading, setLoading] = useState(true);


  const fetchdata = async () => {
    try {
      const response = await fetch(`https://test.planholidays.co.uk/destination/${item}`);
      const res = await response.json();
      setData(res)
      setLoading(false)
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    fetchdata()
  }, [item])

  return (
    <>
    {
      loading?(
        <div className="d-flex justify-content-center align-items-center" style={{height:"600px"}}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      ):(
        <div>
      <Navbar />
      <div>
      <div className='bg-blue px-5 d-flex justify-content-between align-items-center'>
          <div className='text-white ps-5 ms-3 py-2 fnt-20 d-none d-lg-block text-capitalize'>{item}</div>
          {/* <div className='text-white ps-2 py-2 fnt-17 d-block d-lg-none'>{data.length > 0 && data[0]?.region}</div> */}
          <div className='text-white d-flex gap-2 fnt-12 d-none d-lg-block'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white' to="/destination"> Destionations</Link> &lt;&lt; <Link className='text-decoration-none text-white text-capitalize'>{item}</Link></div>
          <div className='text-white d-flex gap-2 fnt-11 d-none d-md-block d-lg-none'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white' to="/destination"> Destionations</Link> &lt;&lt; <Link className='text-decoration-none text-white text-capitalize'>{item}</Link></div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>

          {
            data.map((x, i) => {
              return (
                <div className="col-lg-4 col-md-6 col-12 my-2" key={i}>
                  <div className="card" >
                    <img src={`../new/${x.image[0]}`} style={{ height: "240px" }} className="card-img-top" alt="notFound" />
                    <div className="card-body">
                      <h5 className="card-title fnt-15 text-blue">{x.title.slice(0, 60)}...</h5>
                      <div className='row d-flex justify-content-between'>
                        <div className="col-6">
                          <div>{x.city}</div>
                          <div className='text-danger'>SAVE {x.off}%</div>
                        </div>
                        <div className="col-6">
                          <div style={{ float: "right" }}>{x.packageDays} from</div>
                          <div style={{ float: "right" }}>£ {x.price}/<span className='fnt-14'>pp</span></div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between mt-2 gap-2'>
                        <Link to={`/destination/detail/${x._id}`} className="btn btn-primary w-100 fnt-13 rounded-1 bg-blue">View</Link>
                        <Link to="/inquire" className="btn btn-primary w-100 fnt-13 rounded-1 bg-blue">Request a Quote</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="container-fluid world-map">
        <div className="row ">
          <div className="col-lg-3 d-none d-lg-block">
            <div className='mt-4 pt-1'>
              <img src="/assets/cta.png" className='' alt="" />
            </div>
          </div>
          <div className="col-lg-6 col text-center">
            < div className='pt-5 mb-4'>
              <div className='text-white h5 float-left'>Get In Touch</div>
              <div className='text-white h3'>Speak To A Travel Expert Today!</div>
              <div className='text-white h4'><a className='text-decoration-none text-white' href="tel: 0204 571 1687">CALL 0204 571 1687</a></div>
              <button className="btn btn-blue px-5 py-2 mt-4 text-white mx-auto d-block d-lg-none">Book With Us</button>
              <img src="/assets/cta.png" className='mt-2 d-block d-lg-none mx-auto' alt="" />
            </div>
          </div>
          <div className="col-lg-3 py-4 text-center d-none d-lg-block">
            <div className='pt-5'>
              <Link to="/inquire" className="btn btn-blue text-decoration-none px-5 py-2 text-white">Book With Us</Link>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
      )
    }
    </>
  )
}

export default DestinationCard