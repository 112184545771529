import React, { useContext } from 'react'
import CatContext from '../Context/Context';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom';

const HolidaysSearch = () => {
  const { res } = useContext(CatContext);

  return (
    <div>
      <Navbar />
      <div className='container'>
        <div className='row'>
          {/* Check if there are no results */}
          {res.length>0 && Array.isArray(res) ? (
              res?.map((x, i) => {
              return (
                <div className="col-lg-4 col-md-6 col-12 my-2" key={i}>
                  <div className="card">
                    <img src={`../new/${x.image[0]}`} style={{ height: "240px" }} className="card-img-top" alt="notFound" />
                    <div className="card-body">
                      <h5 className="card-title fnt-15 text-blue">{x.title.slice(0, 60)}...</h5>
                      <div className='row d-flex justify-content-between'>
                        <div className="col-6">
                          <div>{x.city}</div>
                          <div className='text-danger'>SAVE {x.off}%</div>
                        </div>
                        <div className="col-6">
                          <div style={{ float: "right" }}>{x.packageDays} from</div>
                          <div style={{ float: "right" }}>£ {x.price}/<span className='fnt-14'>pp</span></div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between mt-2 gap-2'>
                        <Link to={`/destination/detail/${x._id}`} className="btn btn-primary w-100 fnt-13 rounded-1 bg-blue">View</Link>
                        <Link to="/inquire" className="btn btn-primary w-100 fnt-13 rounded-1 bg-blue">Request a Quote</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            
          ) : (
            <div className="col-12">
            <div className='d-flex align-items-center justify-content-center' style={{height:"500px"}}>
            <div className="alert alert-warning text-center h5 w-100">
              No location found
            <div className='d-block mt-4'>
            <Link to="/" className="btn text-white btn-blue">Return to Home</Link>
            </div>
            </div>
            </div>
          </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HolidaysSearch;
