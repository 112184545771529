import React, { useState } from 'react';
import './Form.css';

const Form = () => {
  const [expand, setExpand] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    destination: '',
    adults: '',
    childs: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    destination: ''
  });

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value
    }));

    // Expand the form when any field is filled
    if (Object.values(form).some((field) => field.trim() !== "")) {
      setExpand(true);
    }

    // Clear the error for the field that was updated
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.name) newErrors.name = 'Name is required';
    if (!form.email) newErrors.email = 'Email is required';
    if (!form.phone) newErrors.phone = 'Phone is required';
    if (!form.destination) newErrors.destination = 'Destination is required';

    setErrors(newErrors);

    // Return true if no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Don't submit the form if there are errors
    }

    try {
      const response = await fetch('https://test.planholidays.co.uk/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (response.ok) {
        alert('Form submitted successfully');
        // Reset form after successful submission
        setForm({
          name: '',
          email: '',
          phone: '',
          date: '',
          destination: '',
          adults: '',
          childs: ''
        });
      } else {
        alert('Error submitting the form');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="bg-blue py-3">
      <div className="container">
        <div className={`form-container ${expand ? 'expand' : ''}`}>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-6 my-2">
                <label className="text-white fw-semibold">Name</label>
                <input
                  type="text"
                  className="form-input fnt-14"
                  placeholder="Name"
                  name="name"
                  value={form.name}
                  onChange={(e) => handleInputChange(0, e)}
                />
                {errors.name && <div className="text-danger">{errors.name}</div>}
              </div>
              <div className="col-lg-3 col-md-3 col-6 my-2">
                <label className="text-white fw-semibold">Email</label>
                <input
                  type="text"
                  className="form-input fnt-14"
                  placeholder="Email"
                  name="email"
                  value={form.email}
                  onChange={(e) => handleInputChange(1, e)}
                />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </div>
              <div className="col-lg-3 col-md-3 col-6 my-2">
                <label className="text-white fw-semibold">Phone</label>
                <input
                  type="text"
                  className="form-input fnt-14"
                  placeholder="Phone #"
                  name="phone"
                  value={form.phone}
                  onChange={(e) => handleInputChange(2, e)}
                />
                {errors.phone && <div className="text-danger">{errors.phone}</div>}
              </div>
              <div className="col-lg-3 col-md-3 col-6 my-2">
                <label className="text-white fw-semibold">Departure Date</label>
                <input
                  type="date"
                  className="form-input fnt-14"
                  name="date"
                  value={form.date}
                  onChange={(e) => handleInputChange(3, e)}
                />
              </div>
            </div>

            <div className={`row additional-fields ${expand ? 'show' : ''}`}>
              <div className="col-lg-3 col-md-3 col-6 my-2">
                <label className="text-white fw-semibold">Destination</label>
                <input
                  type="text"
                  className="form-input fnt-14"
                  name="destination"
                  value={form.destination}
                  onChange={(e) => handleInputChange(4, e)}
                  placeholder="Destination"
                />
                {errors.destination && <div className="text-danger">{errors.destination}</div>}
              </div>
              <div className="col-lg-3 col-md-3 col-6 my-2">
                <label className="text-white fw-semibold">Adults</label>
                <input
                  type="number"
                  className="form-input fnt-14"
                  name="adults"
                  value={form.adults}
                  onChange={(e) => handleInputChange(5, e)}
                  placeholder="Adults"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-6 my-2">
                <label className="fw-semibold text-white">Children</label>
                <input
                  type="number"
                  className="form-input fnt-14"
                  name="childs"
                  value={form.childs}
                  onChange={(e) => handleInputChange(6, e)}
                  placeholder="Children"
                />
              </div>
              <div className="col-md-6 col-12 my-2">
                <div className="d-flex align-items-center my-2">
                  <input type="checkbox" className="text-white" />
                  <span className="fnt-13 ms-2 text-white">
                    Yes, I'd like to receive communication about holiday packages by phone call.
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-12 my-2">
                <button
                  type="submit"
                  className="btn btn-light px-4 py-2 w-100 text-blue fw-semibold"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
