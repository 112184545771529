import React, { useContext, useEffect, useState } from 'react'
import CatContext from '../Context/Context';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { item, setItem } = useContext(CatContext);
  return (
    <div>
      <div className="continer-fluid px-5">
        <div className="row pt-5">
          <div className="col-lg-3">
            <div>
              <div className="h5 text-blue">Popular Destinations</div>
              <ul className='my-4 text-secondary-emphasis'>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' onClick={() => setItem('dubai')} to="/destination/uae/dubai">Dubai Holidays</Link></li>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' onClick={() => setItem('abudhabi')} to="/destination/uae/abu-dhabi">Abu Dhabi Holidays</Link></li>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' onClick={() => setItem('istanbul')} to="/destination/turkey/istanbul">Turkey Holidays</Link></li>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' onClick={() => setItem('phuket')} to="/destination/thailand/phuket">Thailand Holidays</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div>
              <div className="h5 text-blue">Discover</div>
              <ul className='my-4 text-secondary-emphasis'>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' to="/contact">Join Our Team</Link></li>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' to="/inquire">Enquire</Link></li>
                {/* <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' to="/blog">Blog</Link></li> */}
                <li className="py-1"><Link className='text-decoration-none text-secondary-emphasis' to="/">Testimonials</Link></li>
                <li className="py-1"><Link className='text-decoration-none text-secondary-emphasis' to="/multi-destination-packages">Multi Destinations</Link></li>
                <li className="py-1"><Link className='text-decoration-none text-secondary-emphasis' to="/destination">Destinations</Link></li>
                {/* <li className="py-1"><Link className='text-decoration-none text-secondary-emphasis' to="/competition">Competitions</Link></li> */}
                <li className="py-1"><Link className='text-decoration-none text-secondary-emphasis' to="/about">About Us</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div>
              <div className="h5 text-blue">Legal</div>
              <ul className='my-4'>
                <li className="py-1"><Link className='text-decoration-none text-secondary-emphasis' to="/contact">Contact Us</Link></li>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' to="/privacy-notice">Privacy Notice</Link></li>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' to="/terms-and-condition">Terms & Conditions</Link></li>
                <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' to="/atol">ATOL Protection</Link></li>
                {/* <li className='py-1'><Link className='text-decoration-none text-secondary-emphasis' to="">Cookies</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div>
              <div className="h5 text-blue">Contact Info</div>
              <div className='mt-5'>
                <Link className='py-1 d-block text-decoration-none text-black' to='tel:0204 618 9248'><i className='fa-solid fa-phone text-blue-dark'/><span> 0204 618 9248</span></Link>
                <Link className='py-1 d-block text-decoration-none text-black' to='mailto:info@planholidays.co.uk'><i className='fa-solid fa-envelope text-blue-dark'/><span> info@planholidays.co.uk</span></Link>
              </div>
              <div className='d-flex justify-content-around w-50 mt-3'>
                <Link className='text-decoration-none' to="/"><i className=' text-secondary-emphasis h5 fa-brands fa-facebook'/></Link>
                <Link className='text-decoration-none' to="/"><i className=' text-secondary-emphasis h5 fa-brands fa-instagram'/></Link>
                <Link className='text-decoration-none' to="/"><i className=' text-secondary-emphasis h5 fa-brands fa-twitter'/></Link>
                <Link className='text-decoration-none' to="/"><i className=' text-secondary-emphasis h5 fa-brands fa-youtube'/></Link>
              </div>
              <div className='mt-4 d-flex w-auto border border-1'>
                <input type="text" className='outline-none border-none w-75 ps-3' placeholder='Email' />
                <button className="btn btn-blue fw-semibold text-white rounded-0">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container-fluid px-5 text-secondary-emphasis py-3">
          <div className="row">
            <div className="col-12">
              <p className='fnt-14'>© Copyright 2024 Travigence as Plan Holidays - Company Number 15101982. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer