import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const Contact = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="my-5">
          <div className="h2 fw-semibold text-center">Contact Us</div>
          <p className='fnt-15 text-center'>Regardless of the ticking clock, we are here for your assistance.</p>
        </div>
      </div>
      <div>
        <div className='bg-body-tertiary subtle py-3'>
          <div className="h4 text-center">Leave us your info</div>
          <div className="p text-center">and we will get back to you.</div>
          <div className="form text-center mt-3">
            <div>
              <input type="text" className='px-4 py-2 d-none d-md-block w-50 mx-auto outline-none border border-none my-2 rounded-1 fnt-15' placeholder='Name' />
              <input type="text" className='px-4 py-2 d-block d-md-none w-75 mx-auto outline-none border border-none my-2 rounded-1 fnt-15' placeholder='Name' />
            </div>
            <div>
              <input type="text" className='px-4 py-2 d-none d-md-block w-50 mx-auto outline-none border border-none my-2 rounded-1 fnt-15' placeholder='Email' />
              <input type="text" className='px-4 py-2 d-block d-md-none w-75 mx-auto outline-none border border-none my-2 rounded-1 fnt-15' placeholder='Email' />
            </div>
            <div>
              <input type="text" className='px-4 py-2 d-none d-md-block w-50 mx-auto outline-none border border-none my-2 rounded-1 fnt-15' placeholder='Phone' />
              <input type="text" className='px-4 py-2 d-block d-md-none w-75 mx-auto outline-none border border-none my-2 rounded-1 fnt-15' placeholder='Phone' />
            </div>
            <textarea cols="30" rows="10" className='px-4 py-2 outline-none mx-auto border border-none d-none d-md-block w-50 my-2 rounded-1 fnt-15' placeholder='Message'></textarea>
            <textarea cols="30" rows="10" className='px-4 py-2 outline-none mx-auto border border-none d-block d-md-none w-75 my-2 rounded-1 fnt-15' placeholder='Message'></textarea>
          </div>
          <div className='text-center mx-auto pb-5'>
            <button className="btn btn-blue text-white px-4 py-2 w-50 fnt-15 fw-semibold">Submit</button>
          </div>
        </div>
        {/* <div className="container py-4">
          <div className="row">
            <div className="col-4">
              <div className="h4">Contact us </div>
              <a href="tel:0204 618 9248">
              <i className='fa-solid fa-phone h5 navy'/>
              <span className='text-black'> 0204 618 9248</span>
              </a>
            </div>
            <div className="col-4">
              <div className="h4">Email us </div>
              <a href="mailto:info@planholidays.co.uk">
              <i className='fa-solid fa-envelope h5 navy'/>
              <span className='text-black'> info@planholidays.co.uk</span>
              </a>
            </div>
            <div className="col-4">
              <div className="h4">Address </div>
              <a href="/">
              <i className='fa-solid fa-location-dot h5 navy'/>
              <span className='text-black'> info@planholidays.co.uk</span>
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  )
}

export default Contact