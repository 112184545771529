import React, { useContext, useEffect, useState } from 'react'
import CatContext from '../Context/Context';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'
import Form from '../Components/Form'
const MultiDestionation = () => {

  const [data, setData] = useState([])
  const { item, setItem } = useContext(CatContext);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://test.planholidays.co.uk");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);


  return (
    <>
    {
      loading?(
<div className="d-flex justify-content-center align-items-center" style={{height:"600px"}}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      ):(
        <div>
      <Navbar />
      <div className='bg-blue px-5 d-flex justify-content-evenly align-items-center'>
      <div className='container text-white py-2 fnt-20'>Multi Destinations Holidays</div>
      <div className='text-white d-flex gap-2 fnt-12 '><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white' to="/multi-destination-packages"> MultiDesitnation</Link></div>
      </div>
      <div>
        <img src="./assets/elephant.webp" className='w-100' alt="" />
      </div>
      <Form/>
      {/* <div className=''>
          <div><input type="text" /></div>
      </div> */}

      <div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center east-asia'>   <Link onClick={()=> setItem('SoutheastAsia')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/south-east-asia`}>South East Asia</Link></div></div>
            <div className="col-lg-6 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center east-usa'>    <Link onClick={()=> setItem('EastCoastUSA')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/east-coast-usa`}>East Coast USA</Link></div></div>
            <div className="col-lg-4 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center maditranian'> <Link onClick={()=> setItem('MediterraneanIslands')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/mediterranean-islands`}>Mediterranean Islands</Link></div></div>
            <div className="col-lg-4 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center scandinavia'> <Link onClick={()=> setItem('ScandinavianExplorer')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/scandinavian-explorer`}>Scandinavian Explorer</Link></div></div>
            <div className="col-lg-4 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center middle-east'> <Link onClick={()=> setItem('MiddleEasternDiscovery')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/middle-eastern-discovery`}>Middle Eastern Discovery</Link></div></div>
            <div className="col-lg-6 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center australia'>   <Link onClick={()=> setItem('AustralianEastCoast')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/australian-east-coast`}>Australian East Coast Journey</Link></div></div>
            <div className="col-lg-6 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center europe'>      <Link onClick={()=> setItem('CentralEuropean')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/central-european`}>Central European Treasures</Link></div></div>
            <div className="col-lg-4 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center south-usa'>   <Link onClick={()=> setItem('SouthAmerican')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/south-american`}>South American Adventure</Link></div></div>
            <div className="col-lg-4 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center south-africa'><Link onClick={()=> setItem('SouthernAfrica')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/southern-africa`}>Southern Africa Safari</Link></div></div>
            <div className="col-lg-4 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center east-africa'> <Link onClick={()=> setItem('EastAfrica')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/east-african`}>East African Wildlife</Link></div></div>
            <div className="col-lg-6 col-md-6 col-12 text-center my-4"><div className='text-white h5 d-flex align-items-center justify-content-center north-africa'><Link onClick={()=> setItem('NorthAfrica')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/north-african`}>North African Cultural and Historical Journey</Link></div></div>
            <div className="col-lg-6 col-md-6 col-12 text-center my-4"><div className='text-white h5 turkey d-flex align-items-center justify-content-center'>      <Link onClick={()=> setItem('explore')} className='bg-info-light px-3 text-white py-4 rounded-2 text-decoration-none' to={`/multi-destination/explore`}>Explore Dubai, Turkey, Egypt</Link></div></div>
          </div>
        </div>
      </div>
      <div className="container-fluid world-map">
        <div className="row ">
          <div className="col-lg-3 d-none d-lg-block">
            <div className='mt-4 pt-1'>
              <img src="/assets/cta.png" className='' alt="" />
            </div>
          </div>
          <div className="col-lg-6 col text-center">
            < div className='pt-5 mb-4'>
              <div className='text-white h5 float-left'>Get In Touch</div>
              <div className='text-white h3'>Speak To A Travel Expert Today!</div>
              <div className='text-white h4'><a className='text-decoration-none text-white' href="tel: 0204 571 1687">CALL 0204 571 1687</a></div>
              <button className="btn btn-blue px-5 py-2 mt-4 text-white mx-auto d-block d-lg-none">Book With Us</button>
              <img src="/assets/cta.png" className='mt-2 d-block d-lg-none mx-auto' alt="" />
            </div>
          </div>
          <div className="col-lg-3 py-4 text-center d-none d-lg-block">
            <div className='pt-5'>
              <Link to="/inquire" className="btn btn-blue text-decoration-none px-5 py-2 text-white">Book With Us</Link>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
      )
    }
    </>
  )
}

export default MultiDestionation

