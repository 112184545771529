import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'
const Inquire = () => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        destination: '',
        timetocall: '',
        date: '',
        airport: '',
        adults: '',
        childs: '',
        duration: '',
        message: '',
        communicationConsent: false,
        privacyConsent: false,
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setForm((prevForm) => ({
                ...prevForm,
                [name]: checked,
            }));
        } else {
            setForm((prevForm) => ({
                ...prevForm,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if both checkboxes are checked
        if (!form.communicationConsent || !form.privacyConsent) {
            alert('Please agree to the terms before submitting the form.');
            return;
        }

        try {
            const response = await fetch('https://test.planholidays.co.uk/form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form),
            });

            if (response.ok) {
                alert('Form submitted successfully!');
                setForm({
                    name: '',
                    email: '',
                    phone: '',
                    destination: '',
                    timetocall: '',
                    airport: '',
                    adults: '',
                    childs: '',
                    duration: '',
                    message: '',
                    communicationConsent: false,
                    privacyConsent: false,
                });
            } else {
                alert('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting form');
        }
    };

    return (
        <div>
            <Navbar />
            <div>
                <div className='bg-blue px-5 d-flex justify-content-between align-items-center'>
                    <div className='text-white ps-5 ms-3 py-2 fnt-20 d-none d-lg-block text-capitalize'>Inquire Now</div>
                    <div className='text-white d-flex gap-2 fnt-12 d-none d-lg-block'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white'> Inquire</Link></div>
                    <div className='text-white d-flex gap-2 fnt-11 d-none d-md-block d-lg-none'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white'> Inquire</Link></div>
                </div>
            </div>
            <div className='bg-light w-75 mx-auto py-2 mt-4 rounded-4'>
                <div className="container">
                    <div className="my-2">
                        <div className="h4 text-blue fw-semibold">Request a Quote</div>
                        <p className='fnt-15 w-75'>Please fill out the information below and we will reach out to you with an update within 24 hours. You may also provide us a timeframe in the comments section to call you back at your availability & comfort.</p>
                    </div>
                </div>
                <div>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12 my-2">
                                        <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Name</label>
                                    <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                        <input
                                            type="text"
                                            className='w-100 border-none outline-none'
                                            placeholder='Name'
                                            name="name"
                                            value={form.name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 my-2">
                                <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Email</label>
                                    <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                        <input
                                            type="email"
                                            className='w-100 border-none outline-none'
                                            placeholder='Email'
                                            name="email"
                                            value={form.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 my-2">
                                <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Phone</label>
                                    <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                        <input
                                            type="text"
                                            className='w-100 border-none outline-none'
                                            placeholder='Phone'
                                            name="phone"
                                            value={form.phone}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 my-2">
                                <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Departure Airport</label>
                                    <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                        <input
                                            type="text"
                                            className='w-100 border-none outline-none'
                                            placeholder='Departure Airport'
                                            name="airport"
                                            value={form.airport}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 my-2">
                                <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Departure Date</label>
                                    <div className='border d-flex  border-secondary-subtle rounded py-2 px-1 bg-white'>
                                        <input
                                            type="date"
                                            name="date"
                                            style={{}}
                                            value={form.date}
                                            className='text-secondary border-none'
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-12 my-2">
                                <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Destination</label>
                                    <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                        <input
                                            type="text"
                                            className='w-100 border-none outline-none'
                                            placeholder='Destination'
                                            name="destination"
                                            value={form.destination}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 gap-2">
                                    <div className="row">
                                        <div className=' col-lg-6 col-md-6 col-12 my-2'>
                                        <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Adults</label>
                                            <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                                <input
                                                    type="text"
                                                    className='w-100 border-none outline-none'
                                                    placeholder='No.of Adult aged 11+'
                                                    name="adults"
                                                    value={form.adults}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className=' col-lg-6 col-md-6 col-12 my-2'>
                                        <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Children</label>
                                            <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                                <input
                                                    type="text"
                                                    className='w-100 border-none outline-none'
                                                    placeholder='No.of Child aged 2-11'
                                                    name="childs"
                                                    value={form.childs}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 my-2">
                                <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Time To Call</label>
                                    <div className="border border-secondary-subtle rounded py-2 px-1 bg-white">
                                        <select
                                            name="timetocall"
                                            value={form.timetocall}
                                            onChange={(e) => setForm({ ...form, timetocall: e.target.value })}
                                            className="w-100 border-none outline-none text-secondary"
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">Preferred Time to Call</option>
                                            <option value="9am - 1pm">9am - 1pm</option>
                                            <option value="1pm - 5pm">1pm - 5pm</option>
                                            <option value="5pm - 9pm">5pm - 9pm</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12 my-2">
                                <label className='ps-2 fnt-14 text-secondary-emphasis fw-semibold'>Leave a Message</label>
                                    <div className='border border-secondary-subtle rounded p-2 bg-white'>
                                        <textarea
                                            name="message"
                                            value={form.message}
                                            onChange={handleInputChange}
                                            rows="4"
                                            placeholder="Message"
                                            className='w-100 border-none outline-none'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 my-2">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            name="communicationConsent"
                                            checked={form.communicationConsent}
                                            onChange={handleInputChange}
                                        />
                                        <label className="ms-2 fnt-14">
                                            Yes, I'd like to receive communication about holiday packages by phone call.
                                        </label>
                                    </div>
                                </div>

                                {/* Checkbox for privacy consent */}
                                <div className="col-lg-12 my-2 fnt-14">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            name="privacyConsent"
                                            checked={form.privacyConsent}
                                            onChange={handleInputChange}
                                        />
                                        <label className="ms-2">
                                            By submitting this enquiry/email, you consent to your personal data being processed in accordance with our Privacy Policy.
                                        </label>
                                    </div>
                                </div>

                                <div className="col-lg-12 text-center my-3 ">
                                    <button type="submit" className="btn btn-primary w-50 mx-auto">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Inquire;
