import React, { useContext, useEffect, useState } from 'react'
import CatContext from '../Context/Context';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const MulltiDestinationDetail = () => {
  const { item, setItem } = useContext(CatContext);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    destination: '',
    duration: '',
    departureDate: '',
    name: '',
    email: '',
    phone: '',
    adults: '',
    children: '',
    timeSlot: '9AM - 1PM',
  });


  const { id } = useParams();
  const [destination, setDestination] = useState({});

  const fetchData = async () => {
    try {
      const response = await fetch(`https://test.planholidays.co.uk/destination/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch destination details');
      }
      const data = await response.json();
      setDestination(data);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching destination details:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://test.planholidays.co.uk/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Form submitted successfully:', result);
      alert('Form submitted successfully!');
      setFormData({
        destination: '',
        duration: '',
        date: '',
        name: '',
        email: '',
        phone: '',
        adults: '',
        childs: '',
        timetocall: '9AM - 1PM',
      });
    } catch (error) {
      if(formData.email == ""|| formData.phone == "" || formData.name == ""){
        alert('Please fill all required fields');
      }else{
        console.error("Error submitting form:", error);
        alert('Failed to submit the form. Please try again.');
      }
    }
  };

  return (
    <>
    {
      loading?(
<div className="d-flex justify-content-center align-items-center" style={{height:"600px"}}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      ):(
        <div>
      <Navbar />
      {/* <div className='bg-blue text-center py-2 text-white d-md-none'>{data.length > 0 && data[0]?.region}</div> */}
      <div className='bg-blue px-5 d-flex justify-content-between align-items-center'>
          <div className='text-white ps-5 ms-3 py-2 fnt-20 d-none d-lg-block'>{destination?.city}</div>
          {/* <div className='text-white ps-2 py-2 fnt-17 d-block d-lg-none'>{data.length > 0 && data[0]?.region}</div> */}
          <div className='text-white d-flex gap-2 fnt-12 d-none d-lg-block'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white' to="/destination"> Destionations</Link> &lt;&lt; <Link className='text-decoration-none text-white'>{destination?.city}</Link></div>
          <div className='text-white d-flex gap-2 fnt-11 d-none d-md-block d-lg-none'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white' to="/destination"> Destionations</Link> &lt;&lt; <Link className='text-decoration-none text-white'>{destination?.city}</Link></div>
        </div>
      <div className=''>
        <div id="carouselExample" className="carousel slide">
          <div className="carousel-inner">
            {
              destination?.image?.map((image, index) => (
                <div key={index} className={`carousel-item ${index === 0? 'active' : ''}`}>
                  <img src={`/new/${image}`} className="d-block w-100 rounded-3" style={{height:"500px"}} alt="..." />
                </div>
              ))
            }
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className='container'>
      
        <div className="row">
          <div className="col-lg-9 col-12 px-3">
          <div className='pt-3 pb-1'>
              <div className="h4 fw-semibold text-blue">{destination?.title}</div>
              <div className="fnt-16 mt-1">{destination?.detail}</div>
            </div>
            {/* <div className='h4 d-none d-md-block mt-4'>{data.length > 0 && data[0]?.region}, Starting from £ {destination[0]?.price}</div> */}
            {/* <div className='h5 d-block d-md-none mt-4'>{data.length > 0 && data[0]?.region}, Starting from £ {destination[0]?.price}</div> */}
            
            <div className='px-3'>
              <div className="row border bg-light my-4 py-4 rounded-2">
                <div className="col-6 my-2">
                  <div className='d-flex'>
                    <div><i className='fa-solid fa-plane text-blue' /></div>
                    <div className='ms-2'>
                      <div className='fnt-15 fw-semibold'>Return flights from UK</div>
                      <div className='fnt-13'>Inter-connecting flights</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className='d-flex'>
                    <div><i className='fa-solid fa-hotel text-blue' /></div>
                    <div className='ms-2'>
                      <div className='fnt-15 fw-semibold'>Hotels of your choice</div>
                      <div className='fnt-13'>On all destinations</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className='d-flex'>
                    <div><i className="fa-solid fa-building text-blue" /></div>
                    <div className='ms-2'>
                      <div className='fnt-15 fw-semibold'>Double Room</div>
                      <div className='fnt-13'>Triple/Quad available as well</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className='d-flex'>
                    <div><i className="fa-solid fa-mug-saucer text-blue" /></div>
                    <div className='ms-2'>
                      <div className='fnt-15 fw-semibold'>Breakfast Included</div>
                      <div className='fnt-13'>Full Board upon request</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className='d-flex'>
                    <div><i className='fa-solid fa-car text-blue' /></div>
                    <div className='ms-2'>
                      <div className='fnt-15 fw-semibold'>Transfers included</div>
                      <div className='fnt-13'>Upon Request</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className='d-flex'>
                    <div><i className='fa-solid fa-earth-americas text-blue' /></div>
                    <div className='ms-2'>
                      <div className='fnt-15 fw-semibold'>Travel season</div>
                      <div className='fnt-13'>Same discount for any season of the year</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className='d-flex'>
                    <div><i className='fa-solid fa-xmark text-blue' /></div>
                    <div className='ms-2'>
                      <div className='fnt-15 fw-semibold'>Free Cancellations</div>
                      <div className='fnt-13'>(T&C Apply )</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='fw-semibold h5 text-blue'>Attractions</div>
              <div>{
                destination?.attractions?.map((x, i) => {
                  return (
                    <div>
                      <div className='fnt-14 fw-semibold'>{x.name}</div>
                      <div className='fnt-14'>{x.description}</div>
                    </div>
                  )
                })
              }</div>

              <div className='fw-semibold h5 mt-3 text-blue'>Destinations</div>
              <div>{
                destination?.locations?.length > 0 && destination?.locations?.map((x, i) => {
                  return (
                    <div className='py-1'>
                      <div className='fnt-15 fw-semibold'>{x.name} :</div>
                      <div className='fnt-14 ps-2'><span className='fw-semibold'>Hotel: </span> {x.hotel}</div>
                      <div className='fnt-14 ps-2'><span className="fw-semibold">Room: </span> {x.room}</div>
                      <div className='fnt-14 ps-2'><span className="fw-semibold">Meal: </span> {x.meal}</div>
                    </div>
                  )
                })
              }</div>
              <div className='text-center my-4'>
                <Link className="text-decoration-none btn btn-blue text-white fw-semibold rounded-1 w-75 my-2" to="/inquire">Enquire Now</Link>
                <a className="text-decoration-none btn btn-outline-blue fw-semibold rounded-1 w-75 my-2 text-decoration-none" href='tel:0204 618 9248'>Or to speak to a Travel Expert, please tap here to call us</a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 mt-5 col">
            
            <div className='mt-3 border bg-light py-2 px-2 rounded-2'>
              <div className="h5 text-blue">Why Book with us?</div>
              <div className='d-flex'>
                <i className='fa-solid fa-check mt-1 me-2 ms-1 h4 text-blue' />
                <div className='fnt-14 pb-2'>Personal service and expert advice.</div>
              </div>
              <div className='d-flex'>
                <i className='fa-solid fa-check mt-1 me-2 ms-1 h4 text-blue' />
                <div className='fnt-14 pb-2'>First hand knowledge of the destinations we sell.</div>
              </div>
              <div className='d-flex'>
                <i className='fa-solid fa-check mt-1 me-2 ms-1 h4 text-blue' />
                <div className='fnt-14 pb-2'>High number of repeat customers.</div>
              </div>
              <div className='d-flex'>
                <i className='fa-solid fa-check mt-1 me-2 ms-1 h4 text-blue' />
                <div className='fnt-14 pb-2'>We offer only the best hand picked luxury destinations.</div>
              </div>
            </div>
            <div>
            <div className='row bg-light border rounded-3 mt-4 mx-1'>
                <div className="h6 mt-3 text-center">Beat My Quote</div>
                <form onSubmit={handleSubmit}>
                  <div className="mt-1 row">
                    <div className="col-12 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>Destination</label>
                      <input
                        type="text"
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14"
                        placeholder="Destination"
                        name="destination"
                        value={formData.destination}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 px-1">
                      <label className="fnt-14 px-2 text-secondary-subtle">
                        Duration
                      </label>
                      <input
                        type="number"
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14"
                        name="duration"
                        value={formData.duration}
                        onChange={handleInputChange}
                        placeholder='Duration Days'
                        id="duration"
                      />
                    </div>

                    <div className="col-12 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>Departure Date</label>
                      <input
                        type="date"
                        className="w-100 outline-none border py-2 px-2 text-secondary rounded-2 mb-2 bg-white fnt-14"
                        placeholder="Departure Date"
                        name="departureDate"
                        value={formData.date}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-6 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>Name</label>
                      <input
                        type="text"
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-6 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>Email</label>
                      <input
                        type="email"
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-6 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>Phone</label>
                      <input
                        type="text"
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14"
                        placeholder="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-6 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>Adults</label>
                      <input
                        type="number"
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14"
                        placeholder="Adults (11+)"
                        name="adults"
                        value={formData.adults}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-6 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>Children</label>
                      <input
                        type="number"
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14"
                        placeholder="Children (0-11+)"
                        name="children"
                        value={formData.childs}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-6 px-1">
                      <label className='px-2 fnt-14 text-secondary-subtle'>TimeSlot</label>
                      <select
                        className="w-100 outline-none border py-2 px-2 rounded-2 mb-2 bg-white fnt-14 text-secondary"
                        name="timeSlot"
                        value={formData.timetocall}
                        onChange={handleInputChange}
                      >
                        <option>9AM - 1PM</option>
                        <option>1PM - 5PM</option>
                        <option>5PM - 9PM</option>
                      </select>
                    </div>
                    <div>
                      <button className="btn btn-blue w-100 text-white mb-3" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid world-map">
        <div className="row ">
          <div className="col-lg-3 d-none d-lg-block">
            <div className='mt-4 pt-1'>
              <img src="/assets/cta.png" className='' alt="" />
            </div>
          </div>
          <div className="col-lg-6 col text-center">
            < div className='pt-5 mb-4'>
              <div className='text-white h5 float-left'>Get In Touch</div>
              <div className='text-white h3'>Speak To A Travel Expert Today!</div>
              <div className='text-white h4'><a className='text-decoration-none text-white' href="tel: 0204 571 1687">CALL 0204 571 1687</a></div>
              <button className="btn btn-blue px-5 py-2 mt-4 text-white mx-auto d-block d-lg-none">Book With Us</button>
              <img src="/assets/cta.png" className='mt-2 d-block d-lg-none mx-auto' alt="" />
            </div>
          </div>
          <div className="col-lg-3 py-4 text-center d-none d-lg-block">
            <div className='pt-5'>
              <Link to="/inquire" className="btn btn-blue text-decoration-none px-5 py-2 text-white">Book With Us</Link>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
      )
    }
    </>
  )
}

export default MulltiDestinationDetail

