import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const About = () => {
  return (
    <div>
      <Navbar/>
      <div>
        <div className="container">
        <h4 className='h3 text-center my-4 fw-semibold '>About Us</h4>
        <hr />
          <p className='fnt-14'>Plan Holidays is Part Of Travigence Ltd. Planholidays is the UK’s fastest-growing travel agent. <br /> We’re on a mission to open the world to everyone. We’ve become the fastest-growing travel agent by offering unlimited choice, unmatched ease, and unmissable value. <br /> We believe that holidays refresh and renew us. That travel makes new connections and turns strangers into friends. We believe it shouldn’t be an expensive luxury.</p>
          
          <h4 className='h5 fw-semibold'>Offering you unlimited choice</h4>
          <p className="fnt-14">As an independent travel agent, planholidays offers you every possible choice of hotels, flights, and holiday packages. <br />
                                We put together 35,000 hotels with 99 per cent of all flights to bring you 500 billion possible holiday packages – including that perfect one for you.</p>

          <h4 className='h5 fw-semibold'>Allowing you unmatched ease</h4>
          <p className="fnt-14">Our revolutionary search finds your perfect holiday, even if you don’t know where you want to go. Simply tell us what you want. Your dream holiday may be somewhere you’ve never even dreamed of going before. <br />
                                Your trip is easy to manage too, with support through our app, chat and email. We’re here for you before and after booking.</p>

          <h4 className='h5 fw-semibold'>Giving you unmissable value</h4>
          <p className="fnt-14">As a top independent travel agent, we’re able to negotiate the best prices from the largest pool of suppliers. <br />
                                So that when our holiday packaging program combines flights and hotels into your dream holiday package, you save even more. <br />
                                And if you prefer to pay over time, we’ve pioneered the widest range of payment options and the lowest deposits in the industry to put your perfect holiday within reach.</p>
                                
          <h4 className='h5 fw-semibold'>Guaranteeing you the best price</h4>
          <p className="fnt-14">Every day we track millions of prices to ensure you’re offered the lowest price and the most competitive holiday option – every time! <br />
If you book with us and then find your holiday available at a lower price on any other UK-based travel website, within 24 hours of your booking being confirmed, we’ll refund you the difference.</p>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default About