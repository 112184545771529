import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const PrivacyNotice = () => {
  return (
    <div>
      <Navbar/>
      <div className="container py-5">
        <div className="mb-5">
          <div className="h5 fw-semibold text-center">Privacy Notice</div>
          <p className='fnt-15 text-center'>This privacy notice outlines the information we collect, how we use it, and your choices regarding our data protection.</p>
        </div>
        <div className="h5 text-blue">What types of information do we collect about you?</div>
        <p className='fnt-14'>The type of information we collect about you depends on the nature of your interactions with us. Depending on the circumstances, we collect any of the following:</p>
        <ul className='fnt-14'>
          <li>Details about you. Your name, email address, address, telephone number, date of birth, your hotel room preferences, loyalty membership details, frequent flyer membership details, payment details, your reasons for travel (such as for a wedding, birthday or anniversary), meal and other travel preferences or dietary requirements and, if necessary, information about your health to the extent that it’s relevant to your fitness to fly, your holiday itinerary or to provide you with special assistance;</li>
          <li>Identification documents. If you are travelling on a route requiring Advance Passenger Information, your passport or identity card details including your passport number, the country in which your passport was issued and the expiry date;</li>
          <li>Details about the services you arrange with us. Your travel details, including details of your travel itinerary, where you are flying from and to, your booking information including the hotel accommodation, any onward travel details if relevant (for example if you need our assistance for a connecting flight, if you’ve booked transportation or a tour with us), details of experiences or excursions booked through us, your baggage requirements, any upgrade information, your lounge visits, seat preferences, meal preferences or requirements, details of any special assistance you might need from us and any other information relevant to enable us to provide you with the travel or other services that you’ve arranged with us;</li>
          <li>Your interactions with us. Information about your interactions or conversations with us and our people, including when you make enquiries, comments, complaints or submit feedback to us (whether formally via email or simply verbally to our people);</li>
          <li>Your use of our systems and services. Details of the way in which you use our site, app, retail stores, call centres and/or social media pages (please see the “OUR SITE, AND COOKIES” section below for further details).</li>
        </ul>
        <div className="h5 text-blue">How we collect information about you?</div>
        <p className='fnt-14'>How we collect information about you will depend on how you interact with us and what services you arrange with us. Depending on the circumstances, we collect information in any of the following ways:</p>
        <ul className="fnt-14">
          <li>When you browse our site or make a telephone enquiry</li>
          <li>When you book or search for a holiday or other service (such as a flight, cruise, hotel lounge access, transportation or special assistance) via our website.</li>
          <li>When you fill in part of the booking information on our site but do not complete the booking;</li>
          <li>When you apply for a job with us by email or via the site;</li>
          <li>When you contact us via our call centre, social media, post, email or instant messenger;</li>
          <li>When you request a brochure, sign up to receive email updates, participate in any of our competitions, promotions (for example via any social media channels, email or our site), surveys or market research;</li>
        </ul>
        <div className="h5 text-blue">In What Circumstances Do We Need To Collect Your Sensitive Information?</div>
        <p className='fnt-14'>In certain circumstances, we will collect information that is deemed sensitive. This is most likely to include:</p>
        <ul className="fnt-14">
          <li>Information about your health (for example if you ask us to provide you with special assistance during your holiday, or to determine your fitness to fly, or if you specify a meal preference that indicates a medical condition such as celiac disease); and/or</li>
          <li>Information about your religion (for example if you specify a meal preference that indicates a particular religion such as a kosher or halal meal).</li>
        </ul>
      </div>
      <Footer/>
    </div>
  )
}

export default PrivacyNotice