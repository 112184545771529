import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const Atol = () => {
  return (
    <div>
        <Navbar/>
        <div className='container py-5'>
            <h5 className='text-center mb-4 text-blue'>PlanHolidays - ATOL 10341</h5>
                <p className='fnt-15 text-secondary-subtle'>All the flights and flight-inclusive holidays on this website are financially protected by the ATOL scheme. When you pay you will be supplied with an ATOL Certificate. Please ask for it and check to ensure that everything you booked (flights, hotels and other services) is listed on it. Please see our booking conditions for further information or for more information about financial protection and the ATOL Certificate go to: https://www.caa.co.uk/atol-protection/</p>
                <div className='h5 text-blue'>What is ATOL?</div>
                <p className='fnt-15 text-secondary-subtle'>ATOL stands for Air Travel Organiser's Licence. It's a national scheme operated by the Civil Aviation Authority (CAA), and it protects you when you book a holiday with a travel company in the UK. It ensures that you don't lose the money you paid out or become stranded abroad if your travel company collapses. ATOL protects around 20 million holidaymakers and travellers every year</p>
                <div className='h5 text-blue'>How does it work?</div>
                <p className='fnt-15 text-secondary-subtle'>Choosing an ATOL protected travel company means it's been checked out by the CAA, so if the company does collapse you're in safe hands.</p>
                <ul className='fnt-15 text-secondary-subtle'>
                    <li>If you're on holiday, ATOL will make sure you can finish your break and get home as planned.</li>
                    <li>If you haven't yet travelled, ATOL will ensure you get a full refund for the holiday – and in some cases you may be able to continue with your holiday as planned.</li>
                </ul>
                <div className='h5 text-blue'>What's an ATOL certificate?</div>
                <p className='fnt-15 text-secondary-subtle'>Your ATOL certificate is proof that the holiday or flight you have booked is protected by ATOL. It tells you what protection you have and what to do if your travel company collapses.</p>
        </div>
        <Footer/>
    </div>
  )
}

export default Atol