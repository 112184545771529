import React, { useContext, useEffect, useState } from 'react'
import CatContext from '../Context/Context';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

const Destination = () => {
  const { item, setItem } = useContext(CatContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
      {
        loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{height:"600px"}}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>

        ) : (
          <div>
            <Navbar />
            <div>
      <div className='bg-blue px-5 d-flex justify-content-between align-items-center'>
          <div className='text-white ps-5 ms-3 py-2 fnt-20 d-none d-lg-block text-capitalize'>Luxury Destinations</div>
          {/* <div className='text-white ps-2 py-2 fnt-17 d-block d-lg-none'>{data.length > 0 && data[0]?.region}</div> */}
          <div className='text-white d-flex gap-2 fnt-12 d-none d-lg-block'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white' to="/destination"> Destionations</Link></div>
          <div className='text-white d-flex gap-2 fnt-11 d-none d-md-block d-lg-none'><Link className='text-decoration-none text-white ' to="/" >Home </Link> &lt;&lt; <Link className='text-decoration-none text-white' to="/destination"> Destionations</Link></div>
        </div>
      </div>
            <div className="container">
              <div className="row d-flex align-items-center">

                {/* UAE */}
                <Link onClick={() => setItem('')} to="/destination/uae/dubai" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/dubai.jpeg" style={{ width: "230px", height: "160px" }} className="w-100" alt="UAE" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">UAE</div>
                      <div className="fnt-13 text-black">
                        The United Arab Emirates (UAE) is a vibrant and modern country known for its impressive skyscrapers, luxury shopping, and thriving economy...
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Maldives */}
                <Link onClick={() => setItem('maldives')} to="/destination/maldives" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/maldives.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="Maldives" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Maldives</div>
                      <div className="fnt-13 text-black">
                        The Maldives is a tropical paradise known for its crystal-clear waters, white sandy beaches, and luxurious overwater villas...
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Mexico */}
                <Link onClick={() => setItem('cancun')} to="/destination/mexico/cancun" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/mexico.webp" style={{ width: "230px", height: "160px" }} className="w-100" alt="Mexico" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Mexico</div>
                      <div className="fnt-13 text-black">
                        Mexico is a colorful destination rich in history, culture, and cuisine, with ancient Mayan ruins and vibrant cities like Cancun...
                      </div>
                    </div>
                  </div>
                </Link>

                {/* Turkey */}
                <Link onClick={() => setItem('istanbul')} to="/destination/turkey/istanbul" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/turkiye.jpeg" style={{ width: "230px", height: "160px" }} className="w-100" alt="Turkey" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Turkey</div>
                      <div className="fnt-13 text-black">
                        Turkey is a unique crossroads of East and West, offering a mix of historical landmarks, vibrant bazaars, and stunning coastlines...
                      </div>
                    </div>
                  </div>
                </Link>

                {/* USA */}
                <Link onClick={() => setItem('miami')} to="/destination/usa/miami" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/usa.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">USA</div>
                      <div className="fnt-13 text-black">
                        The USA is a vast and diverse country with iconic cities, stunning national parks, and world-class entertainment...
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() => setItem('phuket')} to="/destination/thailand/phuket" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/thailand.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Thailand</div>
                      <div className="fnt-13 text-black">
                        Thailand is a captivating blend of bustling cities, serene temples, and tropical beaches. Known for its delicious cuisine and warm hospitality, it is a favorite destination for adventure seekers and relaxation enthusiasts alike.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() => setItem('barcelona')} to="/destination/spain/barcelona" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/spain.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Spain</div>
                      <div className="fnt-13 text-black">
                        Spain is a vibrant country celebrated for its rich history, artistic heritage, and lively festivals. From the architectural wonders of Barcelona to the sunny beaches of the Costa del Sol, it is a feast for the senses.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() => setItem('singapore')} to="/destination/singapore" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/singapore.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Singapore</div>
                      <div className="fnt-13 text-black">
                        Singapore is a modern and dynamic city-state known for its sleek skyline, lush gardens, and multicultural cuisine. Its unique blend of tradition and innovation makes it a top destination for business and leisure.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() => setItem('srilanka')} to="/destination/srilanka" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/srilanka.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Sri Lanka</div>
                      <div className="fnt-13 text-black">
                        Sri Lanka is an island gem in the Indian Ocean, renowned for its ancient ruins, tea plantations, and wildlife-rich national parks. Its diverse landscapes and warm culture offer a perfect mix of adventure and serenity.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() => setItem('barbados')} to="/destination/caribbean/barbados" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/caribbean.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Caribbean</div>
                      <div className="fnt-13 text-black">
                        The Caribbean is a collection of idyllic islands known for their white sandy beaches, turquoise waters, and laid-back lifestyle. Each island offers unique cultural flavors, making it a sought-after destination for relaxation and adventure.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() => setItem('mauritius')} to="/destination/maritius" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/maritius.webp" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Mauritius</div>
                      <div className="fnt-13 text-black">
                        Mauritius is a tropical haven famous for its turquoise waters, lush greenery, and vibrant coral reefs. This island paradise is a dream destination for honeymooners and nature lovers seeking peace and beauty.
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() => setItem('bali')} to="/destination/indonesia/bali" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/indonesia.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Indonesia</div>
                      <div className="fnt-13 text-black">
                        Indonesia is an archipelago of diverse beauty, from Bali's iconic beaches to Java's ancient temples and Sumatra's lush jungles. Its rich culture and natural wonders attract travelers seeking unique and exotic experiences.
                      </div>
                    </div>
                  </div>
                </Link>


                <Link onClick={() => setItem('puntacana')} to="/destination/dominican/puntacana" className="col-lg-6 col-12 overflow-hidden py-2 text-decoration-none">
                  <div className="row d-flex align-items-center me-2 ms-0 ps-0 border">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img src="../assets/dominican.jpg" style={{ width: "230px", height: "160px" }} className="w-100" alt="USA" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-4">
                      <div className="fnt-14 text-blue">Dominican Republic</div>
                      <div className="fnt-13 text-black">
                        The Dominican Republic is a Caribbean gem with stunning beaches, vibrant culture, and rich history. Its lively music, tropical landscapes, and warm hospitality make it a popular vacation spot for all.
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="container-fluid world-map">
        <div className="row ">
          <div className="col-lg-3 d-none d-lg-block">
            <div className='mt-4 pt-1'>
              <img src="/assets/cta.png" className='' alt="" />
            </div>
          </div>
          <div className="col-lg-6 col text-center">
            < div className='pt-5 mb-4'>
              <div className='text-white h5 float-left'>Get In Touch</div>
              <div className='text-white h3'>Speak To A Travel Expert Today!</div>
              <div className='text-white h4'><a className='text-decoration-none text-white' href="tel: 0204 571 1687">CALL 0204 571 1687</a></div>
              <button className="btn btn-blue px-5 py-2 mt-4 text-white mx-auto d-block d-lg-none">Book With Us</button>
              <img src="/assets/cta.png" className='mt-2 d-block d-lg-none mx-auto' alt="" />
            </div>
          </div>
          <div className="col-lg-3 py-4 text-center d-none d-lg-block">
            <div className='pt-5'>
              <Link to="/inquire" className="btn btn-blue text-decoration-none px-5 py-2 text-white">Book With Us</Link>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
            <Footer />
          </div>
        )
      }
    </>
  );
};

export default Destination;
